import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from "./services/auth-guard.service";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'cadastro',
    loadChildren: () => import('./login/cadastro/cadastro.module').then( m => m.CadastroPageModule)
  },
  {
    path: 'recuperar',
    loadChildren: () => import('./login/recuperar/recuperar.module').then( m => m.RecuperarPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'treinamentos',
    loadChildren: () => import('./treinamentos/treinamentos.module').then( m => m.TreinamentosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'unidades',
    loadChildren: () => import('./unidades/unidades.module').then( m => m.UnidadesPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'eventos',
    loadChildren: () => import('./eventos/eventos.module').then( m => m.EventosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'dicas',
    loadChildren: () => import('./dicas/dicas.module').then( m => m.DicasPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'perfil',
    loadChildren: () => import('./perfil/perfil.module').then( m => m.PerfilPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'redefinir',
    loadChildren: () => import('./login/redefinir/redefinir.module').then( m => m.RedefinirPageModule)
  },
  {
    path: 'quemsomos',
    loadChildren: () => import('./quemsomos/quemsomos.module').then( m => m.QuemsomosPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
